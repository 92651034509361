.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.issuer-account-defined-data-item {
    margin: 10px;
    padding: 10px;
    border: 1px solid black;
}

.app-button {
    margin: 5px;
    padding: 5px;
    border: 1px solid black;
}

.app-button-area {
    margin-top:20px;
}

/* Virtual Terminal Styles */

#terminal-main {
    background-color: #ededed;
    margin-top:50px;
    padding:20px;
    width: 30%;
}

#terminal-main h1 {
    text-align: center;
    margin-bottom: 30px;
}

.terminal-form-section{
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.terminal-form-section-label {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 0.8em;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100px;
}

.terminal-form-section-input {
    width: 100%;
    text-align: left;
}

.terminal-form-section-button {
    justify-content: center;
}

#terminal-response {
    background-color: lightyellow;
    margin-top: 50px;
    padding: 20px;
}

.terminal-button {
    border: none;
    color: white;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    padding: 20px 50px;
    border-radius: 3px;
    margin: 5px;
    transition: text-shadow 0.3s ease;
    background-color: lightblue;
}

.terminal-button:active {
    position: relative;
    top: 1px;
    border-bottom-width: 2px;
}

.terminal-button:hover {
    text-shadow: 2px 2px 0px rgba(0,0,0,.3)
}

.terminal-button:disabled {
    background-color: grey;
}

.terminal-form-expiry-date input {
    width: 3em;
}

.lds-dual-ring {
    display: block;
    width: 80px;
    height: 80px;
    margin-top: 100px;
    margin-left:auto;
    margin-right:auto;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}